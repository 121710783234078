import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from '../../components/Button';
import ProductAddedDialog from '../../components/Dialogs/ProductAddedDialog';
import InputQuantity from '../../components/InputQuantity';
import { handleStoreDiscount } from '../../core/utilities/currency';
import { calculatePrice } from '../../core/utilities/priceCalculator';
import { useBundleStore } from '../../stores/bundles/useBundleStore';
import { Product } from '../../stores/cart/action';
import { useConfigStore } from '../../stores/productConfiguration/useConfigureProduct';
import { useCartStore } from '../../stores/updatedCart/cartStore';
import { SimplifiedProduct } from './ParseServerProductUtilities';

interface ProductDetailActionsProps {
	product: SimplifiedProduct;
	addtNextCall?: () => void;
	disableQuantityField?: boolean;
}

function ProductDetailActions({ product, addtNextCall, disableQuantityField }: ProductDetailActionsProps) {
	const { currentConfig, updateQuantity, getConfiguredProduct } = useConfigStore();
	const addProduct = useCartStore((state) => state.addProduct);
	const { storeId } = useParams();
	const [cartProduct, setCartProduct] = useState<Product>();
	const { isBundle, addProductToBundle } = useBundleStore();

	const allOptionsSelected = () => {
		const availableOptionIds = product?.options.map((option) => option.optionId);
		const optionSet = [...new Set(availableOptionIds)];
		for (const opt of optionSet) {
			if (!Object.values(currentConfig?.options || {}).some((sel) => sel.availableOptionId === opt)) {
				return false;
			}
		}
		return true;
	};

	const handleActionClick = () => {
		if (!currentConfig) {
			return false;
		}
		const configuredProduct = getConfiguredProduct() as Product;

		// We want to use the image from the selected option, if any
		let image = product?.displayPhoto || '';
		Object.values(configuredProduct.options).forEach((option) => {
			if (option.imageUrl) {
				image = option.imageUrl;
			}
		});

		const storeProduct = { ...configuredProduct, image };

		if (isBundle) {
			addProductToBundle({ product: storeProduct });
		} else {
			// if this is a product, we need to add on the discounted rate AND the price modifier

			const result = calculatePrice({
				basePrice: product.basePrice,
				customNamePrice: product.customNamePrice,
				customNumberPrice: product.customNumberPrice,
				options: Object.values(configuredProduct.options),
				customization: {
					name: currentConfig?.custom_name || '',
					number: currentConfig?.custom_number || ''
				},
				discountRate: product.discountRate,
				discountType: product.discountType
			});

			addProduct({ storeId: storeId!, item: { ...storeProduct, price: result.finalPrice } });
			setCartProduct(storeProduct);
		}
		addtNextCall?.();
	};

	return (
		<>
			<Grid container marginTop={2} rowSpacing={2} columnSpacing={2}>
				{!disableQuantityField && (
					<Grid item sm={4} xs={12}>
						<InputQuantity
							value={currentConfig?.quantity || 1}
							onQuantityChange={(newQuantity) => updateQuantity({ quantity: newQuantity })}
						/>
					</Grid>
				)}
				<Grid item sm={8} xs={12}>
					<Button variant="primary" fullWidth={true} disabled={!allOptionsSelected()} onClick={handleActionClick}>
						{isBundle ? 'Next' : 'Add to Cart'}
					</Button>
				</Grid>
			</Grid>
			{cartProduct && (
				<ProductAddedDialog
					label={product.label}
					image={product.displayPhoto || ''}
					onClose={() => setCartProduct(undefined)}
				/>
			)}
		</>
	);
}

export default ProductDetailActions;
