import { Grid } from '@mui/material';
import React from 'react';
import { Check, ChevronRight, Mail, Menu, Minus, Plus, Search, ShoppingCart, Sliders, Star, User } from 'react-feather';

import { GoldButton, GrayButton, PrimaryButton, SecondaryButton, TertiaryButton, WhiteButton } from '../components/Button';
import FeaturedDealsSection from '../components/FeaturedDealsSection';
import Hero from '../components/Hero';
import ProductDetailGenericActions from '../components/ProductDetailGenericActions';
import RelatedProductsSection from '../components/RelatedProductsSection';

function Styleguide() {
	return (
		<div className="padded">
			<h1>Styleguide</h1>

			<h2>Typography</h2>
			<Grid container rowSpacing={1} columnSpacing={2}>
				<Grid item xs={6}>
					<h1>Heading One</h1>
				</Grid>
				<Grid item xs={3}>
					<p>64px / 64px +32px</p>
				</Grid>
				<Grid item xs={3}>
					<p>Thicccboi Bold</p>
				</Grid>

				<Grid item xs={6}>
					<h2>Heading Two</h2>
				</Grid>
				<Grid item xs={3}>
					<p>48px / 48px +24px</p>
				</Grid>
				<Grid item xs={3}>
					<p>Thicccboi Bold</p>
				</Grid>

				<Grid item xs={6}>
					<h3>Heading Three</h3>
				</Grid>
				<Grid item xs={3}>
					<p>40px / 40px +20px</p>
				</Grid>
				<Grid item xs={3}>
					<p>Satoshi Bold</p>
				</Grid>

				<Grid item xs={6}>
					<h4>Heading Four</h4>
				</Grid>
				<Grid item xs={3}>
					<p>36px / 36px +18px</p>
				</Grid>
				<Grid item xs={3}>
					<p>Satoshi Bold</p>
				</Grid>

				<Grid item xs={6}>
					<h5>Heading Five</h5>
				</Grid>
				<Grid item xs={3}>
					<p>24px / 24px +12px</p>
				</Grid>
				<Grid item xs={3}>
					<p>Satoshi Bold</p>
				</Grid>

				<Grid item xs={6}>
					<h6>Heading Six</h6>
				</Grid>
				<Grid item xs={3}>
					<p>20px / 20px + 10px</p>
				</Grid>
				<Grid item xs={3}>
					<p>Satoshi Bold</p>
				</Grid>

				<Grid item xs={6}>
					<p>Body Paragraph</p>
				</Grid>
				<Grid item xs={3}>
					<p>16px / 22px +8px</p>
				</Grid>
				<Grid item xs={3}>
					<p>Satoshi Regular</p>
				</Grid>

				<Grid item xs={6}>
					<strong>Body Paragraph Bold</strong>
				</Grid>
				<Grid item xs={3}>
					<p>16px / 16px</p>
				</Grid>
				<Grid item xs={3}>
					<p>Satoshi Bold</p>
				</Grid>

				<Grid item xs={6}>
					<small>Body Small</small>
				</Grid>
				<Grid item xs={3}>
					<p>14px / 14px</p>
				</Grid>
				<Grid item xs={3}>
					<p>Satoshi Regular</p>
				</Grid>

				<Grid item xs={6}>
					<small>
						<strong>Body Small Bold</strong>
					</small>
				</Grid>
				<Grid item xs={3}>
					<p>14px / 14px</p>
				</Grid>
				<Grid item xs={3}>
					<p>Satoshi Bold</p>
				</Grid>
			</Grid>

			<h2>Icons</h2>
			<div style={{ display: 'flex', gap: 8, padding: 16 }}>
				<Menu />
				<Search />
				<ShoppingCart />
				<Star color={'gold'} />
				<Mail />
				<User />
				<Check color={'green'} />
				<Plus />
				<Minus />
				<ChevronRight />
				<Sliders />
			</div>

			<h3>Buttons</h3>
			<div style={{ display: 'flex', gap: 16, marginBottom: 24 }}>
				<PrimaryButton>Primary Button</PrimaryButton>
				<SecondaryButton>Secondary Button</SecondaryButton>
				<TertiaryButton>Tertiary Button</TertiaryButton>
				<WhiteButton>White Button</WhiteButton>
				<GoldButton>Gold Button</GoldButton>
				<GrayButton>Gray Button</GrayButton>
			</div>
		</div>
	);
}

export default Styleguide;
