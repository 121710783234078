import styled from '@emotion/styled';
import React, { Fragment, ReactElement, useMemo } from 'react';

import PriceComponent from '../../components/PriceComponent';
import useProductPrice from '../../core/hooks/useProductPrice';
import { ProductCustomizationDef } from '../../core/types/ProductCustomizationDef';
import { useConfigStore } from '../../stores/productConfiguration/useConfigureProduct';
import { SimplifiedProduct } from './ParseServerProductUtilities';

interface Props {
	product: SimplifiedProduct;
}

const ProductPriceWrapper = ({ product }: Props): ReactElement => {
	const { currentConfig } = useConfigStore();

	const customization = useMemo(() => {
		const config: ProductCustomizationDef = {
			name: '',
			number: ''
		};
		if (currentConfig?.custom_name) {
			config.name = currentConfig.custom_name;
		}
		if (currentConfig?.custom_number) {
			config.number = currentConfig.custom_number;
		}

		if (!config.name && !config.number) {
			return undefined;
		}

		return config;
	}, [currentConfig]);

	const { price, discountedPercentage } = useProductPrice({
		basePrice: product.basePrice!,
		customPriceOverride: product.customPriceOverride || undefined,
		customNamePrice: product.customNamePrice || undefined,
		customNumberPrice: product.customNumberPrice || undefined,
		discountRate: product.discountRate,
		discountType: product.discountType,
		calculateCustomizations: true,
		options: Object.values(currentConfig?.options || {}),
		customization
	});

	return (
		<PriceComponent
			price={price}
			discountedPercentage={discountedPercentage}
			basePrice={product.basePrice!}
			customPriceOverride={product.customPriceOverride}
		/>
	);
};

export default ProductPriceWrapper;
