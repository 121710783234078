import { OptionValue } from '../../stores/cart/action';
import { ProductCustomizationDef } from '../types/ProductCustomizationDef';

interface PriceCalculationConfig {
	basePrice: number;
	customPriceOverride?: number;
	customNamePrice?: number;
	customNumberPrice?: number;
	options?: OptionValue[];
	discountRate?: number;
	discountType?: 'dollar' | 'percent';
	customization?: ProductCustomizationDef;
	includeCustomizations?: boolean;
	allowNegativeValues?: boolean;
}

interface CalculatedPrice {
	finalPrice: number;
	discountedPercentage?: number;
	breakdown: {
		basePrice: number;
		optionsTotal: number;
		customizationsTotal: number;
		discountAmount: number;
	};
}

const MAX_DISCOUNT_PERCENT_RATE = 76;

export function calculatePrice(config: PriceCalculationConfig): CalculatedPrice {
	const {
		basePrice,
		customPriceOverride,
		options = [],
		discountRate = 0,
		discountType,
		customization,
		customNamePrice = 0,
		customNumberPrice = 0,
		includeCustomizations = true,
		allowNegativeValues = false
	} = config;

	// Initialize price breakdown
	const breakdown = {
		basePrice: 0,
		optionsTotal: 0,
		customizationsTotal: 0,
		discountAmount: 0
	};

	// Calculate base price
	breakdown.basePrice = customPriceOverride && customPriceOverride > 0 ? customPriceOverride : basePrice || 0;

	// Calculate options total
	breakdown.optionsTotal = options.reduce((total, opt) => {
		if (opt.priceModifiedAs && opt.priceModifiedAs !== 0) {
			return total + opt.priceModifiedAs;
		}
		return total;
	}, 0);

	// Calculate customizations if enabled
	if (includeCustomizations) {
		if (customNamePrice && customization?.name?.length) {
			breakdown.customizationsTotal += applyDiscount(customNamePrice, discountRate, discountType, true);
		}
		if (customNumberPrice && customization?.number?.length) {
			breakdown.customizationsTotal += applyDiscount(customNumberPrice, discountRate, discountType, true);
		}
	}

	// Calculate final price with discount
	const subtotal = breakdown.basePrice + breakdown.optionsTotal + breakdown.customizationsTotal;
	const finalPrice = applyDiscount(subtotal, discountRate, discountType, allowNegativeValues);

	breakdown.discountAmount = subtotal - finalPrice;

	// Calculate discount percentage if price was reduced
	const discountedPercentage =
		finalPrice < breakdown.basePrice
			? Math.round(((breakdown.basePrice - finalPrice) / breakdown.basePrice) * 100)
			: undefined;

	return {
		finalPrice,
		discountedPercentage,
		breakdown
	};
}

function applyDiscount(
	value: number,
	discountRate: number,
	discountType?: 'dollar' | 'percent',
	allowNegativeValues = false
): number {
	if (!discountRate || discountRate <= 0) {
		return value;
	}

	if (discountType === 'dollar') {
		const discounted = value - discountRate;
		return allowNegativeValues ? discounted : Math.max(0, discounted);
	}

	if (discountType === 'percent' && discountRate < MAX_DISCOUNT_PERCENT_RATE) {
		const discounted = value - (discountRate / 100) * value;
		return allowNegativeValues ? discounted : Math.max(0, Math.round(discounted));
	}

	return value;
}
