import styled from '@emotion/styled';
import React, { useState } from 'react';

interface InputProps {
	value: string | undefined | null;
	placeholder?: string;
	color?: 'grey' | 'white';
	helperText?: string;
	error?: boolean;
	required?: boolean;
	disabled?: boolean;
	onChange?: (value: string) => void;
}

const InputTextWrapper = styled('div')<{ error: boolean }>`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 4px;
`;

const InputContainer = styled('div')<{ color: 'grey' | 'white'; error: boolean }>`
	display: flex;
	align-items: center;
	gap: 12px;
	padding: 12px 16px;
	border: 1px solid var(--separator);
	background-color: ${({ color }) => (color === 'white' ? '#fff' : '#f5f5f5')};
	border-color: ${({ error }) => (error ? 'red' : 'var(--separator)')};
	border-radius: 8px;
	width: 100%;
	flex-grow: 1;
`;

const TextInput = styled('input')`
	flex: 1;
	background: transparent;
	border: none;
	outline: none;
	font-family: Satoshi-Variable, sans-serif;
	font-size: 16px;
	width: 100%;
`;

const HelperText = styled('div')<{ error: boolean }>`
	color: ${({ error }) => (error ? 'red' : '#6b6b6b')};
	font-size: 12px;
`;

const RequiredIndicator = styled.span`
	color: red;
	margin-left: 4px;
`;
function InputText({ value, color = 'grey', placeholder, helperText, error = false, required = false, onChange }: InputProps) {
	const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		onChange?.(evt.target.value);
	};

	return (
		<InputTextWrapper error={error}>
			<InputContainer color={color} error={error}>
				<TextInput
					type="text"
					placeholder={placeholder}
					value={value || ''}
					onChange={handleChange}
					required={required}
				/>
				{required && <RequiredIndicator>*</RequiredIndicator>}
			</InputContainer>
			{helperText && <HelperText error={error}>{helperText}</HelperText>}
		</InputTextWrapper>
	);
}

export default InputText;
