import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import Button from '../../components/Button';
import Loading from '../../components/LoadingSpinner';
import BodyWrapper from '../../core/components/BodyWrapper';
import { GetAllStoreContentsDocument } from '../../generated/graphql';
import { Package, Product } from '../../stores/cart/action';
import { useCartStore } from '../../stores/updatedCart/cartStore';

const ActionsWrapper = styled('div')`
	display: inline-flex;
	gap: 8px;
	width: 110px;
	margin-top: 12px;
`;

const CheckoutBlock = styled('div')`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 16px 12px;
	border-radius: 12px;
	margin-bottom: 12px;
`;

const TeamCartItemWrapper = styled('div')`
	display: flex;
	justify-content: space-between;
	padding: 12px;
	border: 1px solid var(--separator);
	border-radius: 24px;
	margin-bottom: 12px;
`;

const TeamCartTitle = styled('h4')`
	margin-bottom: 16px;
	font-size: 16px;
`;

type PanelProps = {
	src: string;
};

const TeamCartImage = styled('div')(
	(props: PanelProps) => `
		background-color: var(--white);
		background-image: url("${props.src}");
		background-size: contain;
		width: 100px;
		height: 100px;
		background-repeat: no-repeat;
		background-position: center;
		border:  1px solid var(--separator);
		border-radius: 18px;
	`
);

const TeamCartImages = styled('div')`
	display: flex;
	gap: 8px;
	margin-top: 8px;
	flex-wrap: wrap;
`;

const TeamCartLogo = styled('div')`
	display: inline-flex;
	width: 24px;
	height: 24px;
	background-color: var(--separator);
	border-radius: 50%;
	margin-right: 8px;
	border: 2px solid var(--gold);
`;

const TeamCartName = styled('p')`
	font-weight: bold;
	display: flex;
	align-items: center;
`;

const TeamCartCount = styled('p')`
	font-size: 14px;
	font-weight: 400;
	margin: 10px 0 0 6px;
`;

const CheckoutButtonWrapper = styled('div')`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	padding-right: 6px;
`;

type CombinedCart = {
	teamStoreId: string;
	teamName: string;
	totalQuantity: number;
	items: (Product | Package)[];
};

function CartView() {
	// Pull in cart data from the store
	const storeData = useCartStore((state) => state.storeData);
	const [combinedCarts, setCombinedCarts] = useState<CombinedCart[]>([]);
	const navigate = useNavigate();

	// Use your GraphQL query to fetch team store data
	const { data, loading, error } = useQuery(GetAllStoreContentsDocument, {
		variables: { ids: Object.keys(storeData) }
	});

	useEffect(() => {
		if (data && data.team_store) {
			// Combine the team store data with the local store data
			const newCombinedCarts = Object.keys(storeData)
				.map((teamStoreId) => {
					const teamStore = data.team_store.find((store) => store.id === teamStoreId);
					const items = storeData[teamStoreId];
					const totalQuantity = items.reduce((total, item) => total + ('quantity' in item ? item.quantity : 0), 0);

					if (totalQuantity > 0) {
						return {
							teamStoreId,
							teamName: teamStore ? teamStore.name : `Team ${teamStoreId}`,
							totalQuantity,
							items
						};
					}
					return false;
				})
				.filter(Boolean);

			setCombinedCarts(newCombinedCarts as CombinedCart[]);

			// If there's only one active cart, navigate directly to it
			if (newCombinedCarts.length === 1 && newCombinedCarts[0]) {
				navigate(`/cart/${newCombinedCarts[0].teamStoreId}`);
			}
		}
	}, [data, storeData]);

	if (loading) {
		return (
			<BodyWrapper>
				<Loading />
			</BodyWrapper>
		);
	}

	return (
		<BodyWrapper>
			<Grid container columnSpacing={2} rowSpacing={2}>
				<Grid item xs={12}>
					<TeamCartTitle>Your Team Checkouts</TeamCartTitle>
					{combinedCarts.map((teamCart) => (
						<TeamCartItemWrapper key={teamCart.teamStoreId}>
							<div>
								<TeamCartName>
									<TeamCartLogo></TeamCartLogo>
									<NavLink to={`/TeamStore/${teamCart.teamStoreId}`}>{teamCart.teamName} </NavLink>
								</TeamCartName>
								<TeamCartImages>
									{teamCart.items.map((item, index) => (
										<TeamCartImage key={index} src={item.image} />
									))}
								</TeamCartImages>
							</div>
							<CheckoutButtonWrapper>
								<Button variant={'primary'} onClick={() => navigate(teamCart.teamStoreId)}>
									Proceed to Checkout
								</Button>
								<div>
									<TeamCartCount>{teamCart.items.length} items</TeamCartCount>
								</div>
							</CheckoutButtonWrapper>
						</TeamCartItemWrapper>
					))}
				</Grid>
			</Grid>
		</BodyWrapper>
	);
}

export default CartView;
