import styled from '@emotion/styled';
import React, { ReactNode } from 'react';

interface ButtonBaseProps {
	padding?: 'small' | 'medium';
	fullWidth?: boolean;
	onClick?: () => void;
	children: ReactNode;
	disabled?: boolean;
	className?: string;
	startIcon?: ReactNode;
	disableHover?: boolean;
}

interface ButtonProps extends ButtonBaseProps {
	variant?: 'primary' | 'secondary' | 'tertiary' | 'gray' | 'gold' | 'white' | 'default';
	selected?: boolean;
}

const baseColors: Record<
	Required<ButtonProps>['variant'],
	{ base: string; hover: string; press: string; selected: string; text?: string; border?: string }
> = {
	primary: {
		base: 'var(--primary-base)',
		hover: 'var(--primary-base-hover)',
		press: 'var(--primary-base-press)',
		selected: 'var(--primary-base-selected)',
		text: 'var(--typography-contrast)'
	},
	secondary: {
		base: 'var(--secondary-base)',
		hover: 'var(--secondary-base-hover)',
		press: 'var(--secondary-base-press)',
		selected: 'var(--secondary-base-selected)',
		text: 'var(--typography-contrast)'
	},
	tertiary: {
		base: 'var(--tertiary-base)',
		hover: 'var(--tertiary-base-hover)',
		press: 'var(--tertiary-base-press)',
		selected: 'var(--tertiary-base-selected)',
		text: 'var(--typography-contrast)'
	},
	gray: {
		base: 'var(--gray-base)',
		hover: 'var(--gray-base-hover)',
		press: 'var(--gray-base-press)',
		selected: 'var(--gray-base-selected)'
	},
	gold: {
		base: 'var(--gold-base)',
		hover: 'var(--gold-base-hover)',
		press: 'var(--gold-base-press)',
		selected: 'var(--gold-base-selected)',
		text: 'var(--typography-contrast)'
	},
	white: {
		base: 'var(--white-base)',
		hover: 'var(--white-base-hover)',
		press: 'var(--white-base-press)',
		selected: 'var(--white-base-selected)',
		text: '#000000',
		border: '#000000'
	},
	default: {
		base: 'var(--grey)',
		hover: 'var(--grey-hover)',
		press: 'var(--grey-press)',
		selected: 'var(--grey-selected)'
	}
};

function getBackground(variant: keyof typeof baseColors, selected: boolean) {
	const { base, selected: sel } = baseColors[variant];
	return selected ? sel : base;
}
function getTextColor(variant: keyof typeof baseColors, selected: boolean) {
	const { text } = baseColors[variant];
	if (variant === 'gray' && selected) return 'var(--typography-contrast)';
	return text || 'var(--typography-base)';
}

function getBorderColor(variant: keyof typeof baseColors) {
	const { border } = baseColors[variant];
	return border || 'var(--separator)';
}

const ButtonStyled = styled('button')<ButtonProps>`
	display: ${({ fullWidth }) => (fullWidth ? 'flex' : 'inline-flex')};
	gap: 8px;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: ${({ padding }) => (padding === 'small' ? '12px 24px' : '15px 48px')};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	font-weight: 600;
	border-radius: 16px;
	transition:
		opacity 0.3s ease,
		background-color 0.3s ease;
	opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
	background-color: ${({ variant = 'default', selected = false }) => getBackground(variant, selected)};
	color: ${({ variant = 'default', selected = false }) => getTextColor(variant, selected)};
	border: 1px solid ${({ variant = 'default' }) => getBorderColor(variant)};

	&:hover {
		${({ disabled, disableHover, selected, variant = 'default' }) =>
			!disabled &&
			!disableHover &&
			!selected &&
			`
        background-color: ${baseColors[variant].hover};
        border-color: ${baseColors[variant].hover};
        opacity: 0.9;
    `}
	}

	&:active {
		${({ disabled, disableHover, selected, variant = 'default' }) =>
			!disabled &&
			!disableHover &&
			!selected &&
			`
        background-color: ${baseColors[variant].press};
        border-color: ${baseColors[variant].press};
        opacity: 1;
    `}
	}

	.icon-wrapper {
		display: flex;
		align-items: center;
		svg {
			font-size: 1.5rem;
		}
	}
`;

export function Button(props: ButtonProps) {
	const {
		variant = 'default',
		padding = 'medium',
		onClick,
		fullWidth = false,
		children,
		disabled = false,
		className = '',
		startIcon,
		disableHover = false,
		selected = false,
		...rest
	} = props;

	return (
		<ButtonStyled
			variant={variant}
			padding={padding}
			fullWidth={fullWidth}
			onClick={!disabled ? onClick : undefined}
			disabled={disabled}
			disableHover={disableHover}
			selected={selected}
			className={className}
			{...rest}
		>
			{startIcon && <span className="icon-wrapper">{startIcon}</span>}
			<span>{children}</span>
		</ButtonStyled>
	);
}

export default Button;
export const PrimaryButton = (props: ButtonProps) => <Button {...props} variant="primary" />;
export const SecondaryButton = (props: ButtonProps) => <Button {...props} variant="secondary" />;
export const TertiaryButton = (props: ButtonProps) => <Button {...props} variant="tertiary" />;
export const GrayButton = (props: ButtonProps) => <Button {...props} variant="gray" />;
export const GoldButton = (props: ButtonProps) => <Button {...props} variant="gold" />;
export const WhiteButton = (props: ButtonProps) => <Button {...props} variant="white" />;
