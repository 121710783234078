import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { BundleProductFragment, GetBundleBuilderProductsDocument } from '../../generated/graphql';
import useBundleStorage, { Bundle } from '../../hooks/useBundleStorage';
import BundleProductPreview from './BundleProductPreview';

export type BundleCategories = {
	bundle: boolean;
	full_sub: boolean;
	solid_sub: boolean;
};

type Props = {
	categories: BundleCategories;
	onChange?: (bundle: Bundle) => void;
};

const BundleProductList = ({ categories, onChange }: Props) => {
	const [options, setOptions] = useState({});
	const { data, loading } = useQuery(GetBundleBuilderProductsDocument, {
		variables: { options: options }
	});
	const { addBundleProduct } = useBundleStorage();

	useEffect(() => {
		if (categories.bundle || categories.full_sub || categories.solid_sub) {
			let contains = {};
			if (categories.bundle) {
				contains = { ...contains, bundle: categories.bundle };
			}
			if (categories.full_sub) {
				contains = { ...contains, full_sub: categories.full_sub };
			}
			if (categories.solid_sub) {
				contains = { ...contains, solid_sub: categories.solid_sub };
			}
			setOptions({ _contains: contains });
		} else {
			setOptions({});
		}
	}, [categories]);

	const addProduct = async (product: BundleProductFragment) => {
		const bundle = addBundleProduct(product);
		if (onChange) {
			onChange(bundle);
		}
	};

	if (loading) {
		return (
			<Grid container rowSpacing={2} columnSpacing={2}>
				<Grid key={1} item xs={6} md={3}>
					<BundleProductPreview product={{ id: '1', label: '' }} onClick={() => {}} />
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid container rowSpacing={2} columnSpacing={2}>
			{data?.product.map((p) => (
				<Grid key={p.id} item xs={6} md={3}>
					<BundleProductPreview product={p} onClick={addProduct} />
				</Grid>
			))}
		</Grid>
	);
};

export default BundleProductList;
