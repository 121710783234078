import styled from '@emotion/styled';
import React from 'react';
import { X } from 'react-feather';

function Banner() {
	const BannerWrapper = styled('div')`
		display: flex;
		width: 100%;
		max-width: 1240px;
		margin: auto;
		padding: 10px;
	`;

	const BannerContent = styled('div')`
		flex: 1;
		text-align: center;
	`;

	return (
		<div className="black-box">
			<BannerWrapper>
				<BannerContent>
					<small>
						BAM 2025 Fundraiser going on now! &nbsp;
						<a className={'contrast'} href={'https://teams.breakmark.com/store/bam2025'}>
							Check it out!
						</a>
					</small>
				</BannerContent>
				<X size={16} />
			</BannerWrapper>
		</div>
	);
}

export default Banner;
