import { Grid } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import ProductSection from '../../components/ProductSection';
import { Product } from '../../stores/cart/action';
import { BundleDataLoaderReturn } from './BundleDetailDataLoader';
import BundleDetailHeader from './BundleDetailHeader';
import BundleProductDetailWrapper from './BundleProductDetailWrapper';
import ProductSteps from './BundleProductSteps';
import ReviewBundle from './ReviewBundle';

export interface PriceModifier {
	[key: string]: number;
}

function BundleDetail() {
	const data = useLoaderData() as BundleDataLoaderReturn;
	const [activeStep, setActiveStep] = useState(0);

	const totalSteps = data.bundleProducts.length;
	const isReviewStep = activeStep === totalSteps;
	const totalPrice = data.bundleInfo.store_package[0].custom_price;

	const handleNext = () => setActiveStep((prev) => prev + 1);
	const handleStepClick = (index: number) => {
		setActiveStep(index);
	};

	return (
		<ProductSection>
			<Grid container>
				<Grid item flex={1} overflow="hidden" border="1px solid var(--separator)" borderRadius="20px">
					<BundleDetailHeader bundleName={data.bundleInfo.store_package[0].label || ''} totalPrice={totalPrice}>
						<ProductSteps
							products={data.bundleProducts}
							activeStep={activeStep}
							isReviewStep={isReviewStep}
							totalSteps={totalSteps}
							onStepClick={handleStepClick}
						/>
					</BundleDetailHeader>

					{isReviewStep ? (
						<ReviewBundle bundleInfo={data.bundleInfo} />
					) : (
						<Grid container rowSpacing={2}>
							<BundleProductDetailWrapper
								handleNext={handleNext}
								product={data.bundleProducts[activeStep]}
								isActive={true}
							/>
						</Grid>
					)}
				</Grid>
			</Grid>
		</ProductSection>
	);
}

export default BundleDetail;
