import { useMemo } from 'react';

import { OptionValue } from '../../stores/cart/action';
import { ProductCustomizationDef } from '../types/ProductCustomizationDef';
import { handleStoreDiscount } from '../utilities/currency';

interface PriceCalculationOptions {
	basePrice: number;
	customPriceOverride?: number;
	customNamePrice?: number;
	customNumberPrice?: number;
	options?: OptionValue[];
	discountRate?: number;
	discountType?: 'dollar' | 'percent';
	customization?: ProductCustomizationDef;
	calculateCustomizations?: boolean;
}

interface PriceHookResult {
	price: number;
	discountedPercentage?: number;
}

function getBasePrice(basePrice: number, customPriceOverride?: number) {
	if (customPriceOverride && customPriceOverride > 0) return customPriceOverride;
	return basePrice || 0;
}

function useProductPrice({
	basePrice,
	customPriceOverride,
	customNamePrice,
	customNumberPrice,
	discountRate,
	discountType,
	customization,
	calculateCustomizations = true,
	options
}: PriceCalculationOptions): PriceHookResult {
	const computedPrice = useMemo(() => {
		const base = getBasePrice(basePrice || 0, customPriceOverride || 0);

		if (!calculateCustomizations) {
			return handleStoreDiscount({
				discountRate,
				type: discountType,
				incomingValue: base
			});
		}

		const baselinePrice = handleStoreDiscount({
			discountRate,
			type: discountType,
			incomingValue: base
		});

		let modifyBy = 0;
		options?.forEach((opt) => {
			if (opt.priceModifiedAs && opt.priceModifiedAs !== 0) {
				modifyBy += opt.priceModifiedAs;
			}
		});

		if (customNamePrice && customization?.name?.length) {
			modifyBy += handleStoreDiscount({
				allowNegativeValues: true,
				discountRate,
				type: discountType,
				incomingValue: customNamePrice
			});
		}

		if (customNumberPrice && customization?.number?.length) {
			modifyBy += handleStoreDiscount({
				allowNegativeValues: true,
				discountRate,
				type: discountType,
				incomingValue: customNumberPrice
			});
		}

		return baselinePrice + modifyBy;
	}, [
		basePrice,
		customPriceOverride,
		customNamePrice,
		customNumberPrice,
		discountRate,
		discountType,
		customization,
		calculateCustomizations,
		options
	]);

	const discountedPercentage =
		computedPrice < basePrice ? Math.round(((basePrice - computedPrice) / basePrice) * 100) : undefined;

	return { price: computedPrice, discountedPercentage };
}

export default useProductPrice;
