import React, { useEffect } from 'react';

import useBundleStore from '../../stores/bundles/useBundleStore';
import { useConfigStore } from '../../stores/productConfiguration/useConfigureProduct';
import { SimplifiedProduct, TransformProductForConfig } from '../ProductDetail/ParseServerProductUtilities';
import ProductDetail from '../ProductDetail/ProductDetail';

interface Props {
	isActive: boolean;
	product: SimplifiedProduct;
	handleNext: () => void;
}

function BundleProductDetailWrapper({ isActive, product, handleNext }: Props) {
	const clearConfig = useConfigStore((state) => state.clearConfig);
	const initConfig = useConfigStore((state) => state.initConfig);
	const hydrateConfig = useConfigStore((state) => state.hydrateConfig);
	const bundleItem = useBundleStore((state) => state.bundleItems[product.id]);

	useEffect(() => {
		if (isActive) {
			const configProduct = TransformProductForConfig(product);
			if (bundleItem) {
				hydrateConfig({ baseProduct: bundleItem });
			} else {
				initConfig({ baseProduct: configProduct });
			}
		}
		return () => {
			clearConfig();
		};
	}, [isActive, initConfig, clearConfig, product, bundleItem]);

	return <ProductDetail disablePriceLabelForBundle disableQuantityField addtNextCall={handleNext} product={product} />;
}

export default BundleProductDetailWrapper;
