import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { RouteDefinitions } from '../routeDefinitions';
import { getImageUrl } from '../utils/imageHelper';
import Button, { PrimaryButton } from './Button';
import ImageCredit from './ImageCredit';
import PromoStat from './PromoStat';

function Hero() {
	const HeroWrapper = styled('div')`
		display: flex;
		width: 100%;
		max-width: 1240px;
		margin: auto;
	`;

	const HeroText = styled('div')`
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 20px;
		gap: 32px;
	`;

	const Stats = styled('div')`
		display: flex;
		gap: 32px;
		@media only screen and (max-width: 900px) {
			gap: 16px;
		}
	`;

	const Separator = styled('div')`
		width: 1px;
		border-right: 1px solid var(--separator);
	`;

	type ImageProps = {
		src: string;
	};
	const HeroImage = styled('div')((props: ImageProps) => ({
		flex: 1,
		aspectRatio: 1,
		backgroundImage: `url("${props.src}")`,
		backgroundSize: 'cover'
	}));

	return (
		<div className={'grey-box'}>
			<HeroWrapper>
				<Grid container>
					<Grid item md={6} xs={12} display={'flex'}>
						<HeroText>
							<h1 className={'no-margin'}>BUILD A CUSTOM BUNDLE FOR YOUR TEAM TODAY</h1>
							<p>
								Pick from our diverse range of meticulously crafted gear and we will help you design something
								amazing catered to your style at an incredible price.
							</p>
							<div>
								<NavLink to={RouteDefinitions.TEAM_APPAREL()}>
									<PrimaryButton>Customize Now</PrimaryButton>
								</NavLink>
							</div>
							<Stats>
								<PromoStat stat={'1,000+'} name={'Collaborations'} />
								<Separator />
								<PromoStat stat={'50,000+'} name={'Happy Customers'} />
								<Separator />
								<PromoStat stat={'Infinite'} name={'Design Possibilities'} />
							</Stats>
						</HeroText>
					</Grid>
					<Grid item md={6} xs={12} style={{ position: 'relative' }}>
						<HeroImage src={getImageUrl('homepage.jpg')} />
						<ImageCredit style={{ position: 'absolute', top: '5px', right: '10px' }}>Jonathan Red</ImageCredit>
					</Grid>
				</Grid>
			</HeroWrapper>
		</div>
	);
}

export default Hero;
