import styled from '@emotion/styled';
import React, { Fragment, ReactElement } from 'react';

import { formatCurrencyValueFromServer } from '../core/utilities/currency';

interface Props {
	basePrice: number;
	price: number;
	discountedPercentage?: number;
	customPriceOverride?: number;
}

const PriceBlock = styled('div')`
	display: flex;
	gap: 8px;
	margin-left: 8px;
`;

const ProductPriceStyle = styled('h5')`
	color: var(--typography-base);
`;

const OriginalPrice = styled('h5')`
	text-decoration: line-through;
	color: var(--typography-muted);
`;

const DiscountBadge = styled('div')`
	background: var(--discount-bg);
	color: var(--typography-discount);
	padding: 4px 8px;
	border-radius: 10px;
	height: 24px;
	small {
		font-size: 12px;
	}
`;

const calculatePercentDifference = (base: number, override: number): number => {
	return Math.round(((base - override) / base) * 100);
};

const PriceComponent = ({ price, basePrice, discountedPercentage, customPriceOverride }: Props): ReactElement => {
	return (
		<PriceBlock>
			<ProductPriceStyle>{formatCurrencyValueFromServer(price)}</ProductPriceStyle>
			{discountedPercentage && (
				<Fragment>
					<OriginalPrice>{formatCurrencyValueFromServer(basePrice)}</OriginalPrice>
					<DiscountBadge>
						<small>-{discountedPercentage}%</small>
					</DiscountBadge>
				</Fragment>
			)}
			{!discountedPercentage && customPriceOverride && (
				<Fragment>
					<OriginalPrice>{formatCurrencyValueFromServer(basePrice)}</OriginalPrice>
					<DiscountBadge>
						<small>-{calculatePercentDifference(basePrice, customPriceOverride)}%</small>
					</DiscountBadge>
				</Fragment>
			)}
		</PriceBlock>
	);
};

export default PriceComponent;
