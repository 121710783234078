import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const Wrapper = styled('div')`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1240px;
	margin: auto;
	padding: 10px;
`;

const Table = styled('table')`
	width: 100%;
	border: 1px solid var(--separator);
	border-radius: 8px;

	th {
		background-color: var(--grey);
		padding: 10px;
		text-align: center;
	}
`;

type CellProps = {
	sizing?: string;
	activeSizing: string;
};
const Cell = styled('td')<CellProps>`
	padding: 10px;
	text-align: center;
	cursor: pointer;
	background-color: ${(props) => (props.sizing === props.activeSizing ? 'var(--primary-base)' : '')};
	&:hover {
		background-color: var(--primary-base-hover);
		text-decoration: none;
	}

	a {
		:hover {
			text-decoration: none;
		}
	}
`;

const ImageContainer = styled('div')`
	max-width: 1000px;
	width: 100%;
	margin: 10px auto;
`;

const StyledImage = styled('img')`
	width: 100%;
	height: auto;
	display: block;
`;

const ImageWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	margin-top: 20px;
`;

function DesignSizing() {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const [activeSizing, setActiveSizing] = React.useState<string>(searchParams.get('type') || 'mensShortSleeve');

	useEffect(() => {
		const type = searchParams.get('type');
		if (type && SizeImages[type]) {
			setActiveSizing(type);
		} else if (!type) {
			setSearchParams({ type: 'mensShortSleeve' });
		}
	}, [searchParams, setSearchParams]);

	const handleSizingClick = (sizing: string) => {
		setActiveSizing(sizing);
		setSearchParams({ type: sizing });
	};

	const SizeImages: { [key: string]: any } = {
		mensShortSleeve: {
			image: 'https://assets.breakmark.com/pub/sizing/short-sleeve.webp',
			chart: 'https://assets.breakmark.com/pub/sizing/short-sleeve-mens.webp'
		},
		mensLongSleeve: {
			image: 'https://assets.breakmark.com/pub/sizing/mens_ls_v2.png',
			chart: 'https://assets.breakmark.com/pub/sizing/long-sleeve-mens.webp'
		},
		mensLongSleeveHooded: {
			image: 'https://assets.breakmark.com/pub/sizing/long-sleeve-hooded.webp',
			chart: 'https://assets.breakmark.com/pub/sizing/long-sleeve-hooded-mens.webp'
		},
		mensTankTop: {
			image: 'https://assets.breakmark.com/pub/sizing/tank.webp',
			chart: 'https://assets.breakmark.com/pub/sizing/tank-mens.webp'
		},
		mensTankReversible: {
			image: 'https://assets.breakmark.com/pub/sizing/mens_rev_v2.png',
			chart: 'https://assets.breakmark.com/pub/sizing/tank-reversible-mens.webp'
		},
		womensShortSleeve: {
			image: 'https://assets.breakmark.com/pub/sizing/womens_ss_v2.png',
			chart: 'https://assets.breakmark.com/pub/sizing/short-sleeve-womens.webp'
		},
		womensLongSleeve: {
			image: 'https://assets.breakmark.com/pub/sizing/long-sleeve.png',
			chart: 'https://assets.breakmark.com/pub/sizing/long-sleeve-womens.webp'
		},
		womensLongSleeveHooded: {
			image: 'https://assets.breakmark.com/pub/sizing/womens_lsh_v2.png',
			chart: 'https://assets.breakmark.com/pub/sizing/long-sleeve-hooded-womens.webp'
		},
		womensTankTop: {
			image: 'https://assets.breakmark.com/pub/sizing/womens_tt_v2.png',
			chart: 'https://assets.breakmark.com/pub/sizing/womens_tt_chart_v2.png'
		},
		womensTankReversible: {
			image: 'https://assets.breakmark.com/pub/sizing/womens_rev_v2.png',
			chart: 'https://assets.breakmark.com/pub/sizing/womens_rev_chart_v2.png'
		},
		youthShortSleeve: {
			image: 'https://assets.breakmark.com/pub/sizing/short-sleeve.webp',
			chart: 'https://assets.breakmark.com/pub/sizing/short-sleeve-youth.webp'
		},
		youthLongSleeve: {
			image: 'https://assets.breakmark.com/pub/sizing/youth_ls_v2.png',
			chart: 'https://assets.breakmark.com/pub/sizing/long-sleeve-youth.webp'
		},
		youthLongSleeveHooded: {
			image: 'https://assets.breakmark.com/pub/sizing/long-sleeve-hooded.webp',
			chart: 'https://assets.breakmark.com/pub/sizing/long-sleeve-hooded-youth.webp'
		},
		youthTankTop: {
			image: 'https://assets.breakmark.com/pub/sizing/tank.webp',
			chart: 'https://assets.breakmark.com/pub/sizing/tank-youth.webp'
		},
		youthTankReversible: {
			image: 'https://assets.breakmark.com/pub/sizing/youth_rev_v2.png',
			chart: 'https://assets.breakmark.com/pub/sizing/tank-reversible-youth.webp'
		},
		shorts5inch: {
			image: 'https://assets.breakmark.com/pub/sizing/shorts.webp',
			chart: 'https://assets.breakmark.com/pub/sizing/shorts-5inch.png'
		},
		shorts8inch: {
			image: 'https://assets.breakmark.com/pub/sizing/shorts.webp',
			chart: 'https://assets.breakmark.com/pub/sizing/shorts-8inch.webp'
		},
		shortsWomen: {
			image: 'https://assets.breakmark.com/pub/sizing/shorts.webp',
			chart: 'https://assets.breakmark.com/pub/sizing/shorts-womens.png'
		},
		shortsYouth8inch: {
			image: 'https://assets.breakmark.com/pub/sizing/shorts.webp',
			chart: 'https://assets.breakmark.com/pub/sizing/shorts-youth-8inch.webp'
		},
		socks: {
			image: 'https://assets.breakmark.com/pub/sizing/socks_v2.png',
			chart: 'https://assets.breakmark.com/pub/sizing/socks.webp'
		},
		fillZipJacket: {
			chart: 'https://assets.breakmark.com/pub/sizing/hooded_full_zip_jacket.png'
		},
		womensfillZipJacket: {
			chart: 'https://assets.breakmark.com/pub/sizing/hooded_full_zip_jacket.png'
		}
	};

	return (
		<Wrapper>
			<div style={{ padding: '20px', textAlign: 'end' }}>
				<a href={'/images/sizing/SizeChartsAll.pdf'} target="_blank" rel="noopener noreferrer">
					Download SizeChartsAll.pdf
				</a>
			</div>
			<Table>
				<thead>
					<tr>
						<th></th>
						<th>Short Sleeve</th>
						<th>Long Sleeve</th>
						<th>Long Sleeve Hooded</th>
						<th>Tank Top</th>
						<th>Tank Reversible</th>
						<th>Full Zip Jacket</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th>Mens</th>
						<Cell
							sizing={'mensShortSleeve'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('mensShortSleeve')}
						>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'mensLongSleeve'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('mensLongSleeve')}
						>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'mensLongSleeveHooded'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('mensLongSleeveHooded')}
						>
							<a>view</a>
						</Cell>
						<Cell sizing={'mensTankTop'} activeSizing={activeSizing} onClick={() => handleSizingClick('mensTankTop')}>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'mensTankReversible'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('mensTankReversible')}
						>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'fillZipJacket'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('fillZipJacket')}
						>
							<a>view</a>
						</Cell>
					</tr>
					<tr>
						<th>Womens</th>
						<Cell
							sizing={'womensShortSleeve'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('womensShortSleeve')}
						>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'womensLongSleeve'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('womensLongSleeve')}
						>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'womensLongSleeveHooded'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('womensLongSleeveHooded')}
						>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'womensTankTop'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('womensTankTop')}
						>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'womensTankReversible'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('womensTankReversible')}
						>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'womensfillZipJacket'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('womensfillZipJacket')}
						>
							<a>view</a>
						</Cell>
					</tr>
					<tr>
						<th>Youth</th>
						<Cell
							sizing={'youthShortSleeve'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('youthShortSleeve')}
						>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'youthLongSleeve'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('youthLongSleeve')}
						>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'youthLongSleeveHooded'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('youthLongSleeveHooded')}
						>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'youthTankTop'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('youthTankTop')}
						>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'youthTankReversible'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('youthTankReversible')}
						>
							<a>view</a>
						</Cell>
					</tr>
				</tbody>
				<thead>
					<tr>
						<th></th>
						<th>Shorts 8"</th>
						<th>Shorts 5"</th>
						<th>Shorts Women</th>
						<th>Shorts Youth 8"</th>
						<th>Socks</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th></th>
						<Cell sizing={'shorts8inch'} activeSizing={activeSizing} onClick={() => handleSizingClick('shorts8inch')}>
							<a>view</a>
						</Cell>
						<Cell sizing={'shorts5inch'} activeSizing={activeSizing} onClick={() => handleSizingClick('shorts5inch')}>
							<a>view</a>
						</Cell>
						<Cell sizing={'shortsWomen'} activeSizing={activeSizing} onClick={() => handleSizingClick('shortsWomen')}>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'shortsYouth8inch'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('shortsYouth8inch')}
						>
							<a>view</a>
						</Cell>
						<Cell sizing={'socks'} activeSizing={activeSizing} onClick={() => handleSizingClick('socks')}>
							<a>view</a>
						</Cell>
					</tr>
				</tbody>
			</Table>
			<ImageWrapper>
				<ImageContainer>
					<StyledImage src={SizeImages[activeSizing].chart} alt="Size Chart" />
				</ImageContainer>
				{SizeImages[activeSizing].image && (
					<ImageContainer>
						<StyledImage src={SizeImages[activeSizing].image} alt="Product" />
					</ImageContainer>
				)}
			</ImageWrapper>
		</Wrapper>
	);
}

export default DesignSizing;
