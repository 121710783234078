import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';

import Button, { PrimaryButton } from './Button';
import ImageCredit from './ImageCredit';

interface HeroImageProps {
	title?: string;
	description?: string;
	imageSrc: string;
	imageCredit?: string;
	buttonText?: string;
	buttonLink?: string;
	fullWidth?: boolean;
	onButtonClick?: () => void;
	height?: 'small' | 'medium' | 'large' | 'full';
	minHeight?: string;
}

const HeroWrapper = styled('div')<{ fullWidth?: boolean; height: string; isClickable?: boolean }>`
	display: flex;
	width: 100%;
	max-width: ${(props) => (props.fullWidth ? '100%' : '1240px')};
	margin: auto;
	position: relative;
	overflow: hidden;
	min-height: ${(props) => props.height};
	cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};

	@media (max-width: 768px) {
		min-height: ${(props) => (props.height === '100vh' ? '100vh' : `calc(${props.height} * 0.8)`)};
	}
`;

const HeroText = styled('div')`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 40px;
	gap: 32px;
	z-index: 1;
	color: white;
	background: rgba(0, 0, 0, 0.4);
`;

const HeroImage = styled('div')<{ src: string }>`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url('${(props) => props.src}');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	/* Recommended image dimensions:
	   - Minimum width: 1920px
	   - Minimum height: 1080px
	   - Aspect ratio: 16:9 or 2:1
	   - Format: JPG or WebP
	   - File size: Optimize to < 400KB
	*/
`;

function GenericHeroImage({
	title,
	description,
	imageSrc,
	imageCredit,
	buttonText,
	buttonLink,
	fullWidth = false,
	onButtonClick,
	height = 'medium',
	minHeight
}: HeroImageProps) {
	const getHeight = () => {
		if (minHeight) return minHeight;

		switch (height) {
			case 'small':
				return '400px';
			case 'medium':
				return '600px';
			case 'large':
				return '800px';
			case 'full':
				return '100vh';
			default:
				return '600px';
		}
	};

	const renderButton = () => {
		if (!buttonText) return null;

		if (buttonLink) {
			return (
				<NavLink to={buttonLink}>
					<PrimaryButton onClick={onButtonClick}>{buttonText}</PrimaryButton>
				</NavLink>
			);
		}

		return <PrimaryButton onClick={onButtonClick}>{buttonText}</PrimaryButton>;
	};

	const handleWrapperClick = () => {
		if (buttonLink) {
			window.location.href = buttonLink;
		} else if (onButtonClick) {
			onButtonClick();
		}
	};

	const content = (
		<>
			<HeroImage src={imageSrc} />
			{title && (
				<Grid container>
					<Grid item md={6} xs={12} display={'flex'}>
						<HeroText>
							<h1 className={'no-margin'}>{title}</h1>
							<p>{description}</p>
							{renderButton()}
						</HeroText>
					</Grid>
				</Grid>
			)}
			{imageCredit && (
				<ImageCredit style={{ position: 'absolute', bottom: '10px', right: '10px', color: 'white' }}>
					{imageCredit}
				</ImageCredit>
			)}
		</>
	);

	if (buttonLink) {
		return (
			<NavLink to={buttonLink} style={{ textDecoration: 'none' }}>
				<HeroWrapper fullWidth={fullWidth} height={getHeight()} isClickable={true}>
					{content}
				</HeroWrapper>
			</NavLink>
		);
	}

	return (
		<HeroWrapper fullWidth={fullWidth} height={getHeight()} isClickable={!!onButtonClick} onClick={onButtonClick}>
			{content}
		</HeroWrapper>
	);
}

export default GenericHeroImage;
