import { Grid } from '@mui/material';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import ProductSection from '../../components/ProductSection';
import { SimplifiedProduct } from './ParseServerProductUtilities';
import ProductCustomization from './ProductCustomization';
import ProductDetailActions from './ProductDetailActions';
import ProductDetailHeader from './ProductDetailHeader';
import ProductDetailImageViewer from './ProductDetailImageViewer';
import ProductPriceWrapper from './ProductPriceWrapper';

interface Props {
	product: SimplifiedProduct;
	addtNextCall?: () => void;

	// Bundle Specific Helpers
	disableQuantityField?: boolean;
	disablePriceLabelForBundle?: boolean;
	customActionContainer?: React.ReactNode;
	customDetails?: React.ReactNode;
}

function ProductDetail({
	product,
	addtNextCall,
	disableQuantityField,
	disablePriceLabelForBundle,
	customActionContainer,
	customDetails
}: Props) {
	return (
		<ProductSection>
			<Grid container rowSpacing={2} columnSpacing={3}>
				<ProductDetailImageViewer product={product} />
				<Grid item md={6} sm={12}>
					<ProductDetailHeader product={product} />
					{!disablePriceLabelForBundle && (
						<Grid container display={'flex'}>
							<Grid item xs={6}>
								<ProductPriceWrapper product={product} />
							</Grid>
						</Grid>
					)}
					<ReactMarkdown remarkPlugins={[remarkGfm]}>{product.description}</ReactMarkdown>
					<ProductCustomization product={product} />
					{customDetails}
					<ReactMarkdown remarkPlugins={[remarkGfm]}>{product.additionalDescription}</ReactMarkdown>
					{!customActionContainer && (
						<ProductDetailActions
							product={product}
							addtNextCall={addtNextCall}
							disableQuantityField={disableQuantityField}
						/>
					)}
					{customActionContainer}
				</Grid>
			</Grid>
		</ProductSection>
	);
}

export default ProductDetail;
