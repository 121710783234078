import styled from '@emotion/styled';
import React, { Fragment, ReactElement } from 'react';

import useProductPrice from '../core/hooks/useProductPrice';
import { ProductCustomizationDef } from '../core/types/ProductCustomizationDef';
import { ProductOptionDef } from '../core/types/ProductOptionDef';
import { formatCurrencyValueFromServer } from '../core/utilities/currency';

interface Props {
	basePrice: number;
	customPriceOverride?: number;
	customNamePrice?: number;
	customNumberPrice?: number;
	options?: ProductOptionDef[];
	discountRate?: number;
	discountType?: 'dollar' | 'percent';
	customization?: ProductCustomizationDef;
	calculateCustomizations?: boolean;
}

const PriceBlock = styled('div')`
	display: flex;
	gap: 8px;
	margin-left: 8px;
`;

const ProductPriceStyle = styled('h5')`
	color: var(--typography-base);
`;

const OriginalPrice = styled('h5')`
	text-decoration: line-through;
	color: var(--typography-muted);
	/* &:hover {
		text-decoration: none;
	} */
`;

const DiscountBadge = styled('div')`
	background: var(--discount-bg);
	color: var(--typography-discount);
	padding: 4px 8px;
	border-radius: 10px;
	height: 24px;
	small {
		font-size: 12px;
	}
`;

const calculatePercentDifference = (base: number, override: number): number => {
	return Math.round(((base - override) / base) * 100);
};

const ProductPrice = ({
	basePrice,
	customPriceOverride,
	customNamePrice,
	customNumberPrice,
	options,
	discountRate,
	discountType,
	customization,
	calculateCustomizations = true
}: Props): ReactElement => {
	const { price } = useProductPrice({
		basePrice,
		customPriceOverride,
		customNamePrice,
		customNumberPrice,
		discountRate,
		discountType,
		customization,
		calculateCustomizations
	});

	return (
		<PriceBlock>
			<ProductPriceStyle>{formatCurrencyValueFromServer(price)}</ProductPriceStyle>
			{discountRate && (
				<Fragment>
					<OriginalPrice>{formatCurrencyValueFromServer(basePrice)}</OriginalPrice>
					<DiscountBadge>
						<small>-{discountRate}%</small>
					</DiscountBadge>
				</Fragment>
			)}
			{!discountRate && customPriceOverride && (
				<Fragment>
					<OriginalPrice>{formatCurrencyValueFromServer(basePrice)}</OriginalPrice>
					<DiscountBadge>
						<small>-{calculatePercentDifference(basePrice, customPriceOverride)}%</small>
					</DiscountBadge>
				</Fragment>
			)}
		</PriceBlock>
	);
};

export default ProductPrice;
