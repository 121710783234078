import styled from '@emotion/styled';
import React from 'react';
import { NavLink } from 'react-router-dom';

import useProductPrice from '../core/hooks/useProductPrice';
import { formatCurrencyValueFromServer } from '../core/utilities/currency';

export interface StorePreviewCardProps {
	id: string;
	name: string;
	basePrice: number;
	image?: string;
	customPriceOverride?: number;
	discountRate?: number;
	discountType?: 'dollar' | 'percent';
	navPath?: string;
}

interface PanelProps {
	src: string;
}

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	height: 100%;
	cursor: pointer;
	border-radius: 20px;
	transition: all 0.3s ease;
	&:hover {
		transform: scale(1.03);
	}
`;

const ImagePanel = styled('div')(
	(props: PanelProps) => `
    background-color: var(--white);
    background-image: url("${props.src}");
    background-size: contain;
    aspect-ratio: 1;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
    border: 1px solid var(--separator);
    transition: all 0.3s ease;
    flex-shrink: 0;
  `
);

const InfoSection = styled('div')`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: space-between;
`;

const Title = styled('div')`
	margin: 8px 8px 0;
	font-size: 16px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const PriceRow = styled('div')`
	margin: 8px;
	font-size: 14px;
	display: flex;
	align-items: center;
	gap: 8px;
`;

const DiscountedPrice = styled('span')`
	font-weight: bold;
	color: var(--typography-base);
`;

const OriginalPrice = styled('span')`
	text-decoration: line-through;
	color: var(--typography-muted);
	font-size: 0.9em;
`;

const DiscountBadge = styled('span')`
	background: var(--discount-bg);
	color: var(--typography-discount);
	padding: 2px 4px;
	border-radius: 4px;
	font-size: 0.8em;
`;

export function StorePreviewCard({
	id,
	image,
	name,
	basePrice,
	customPriceOverride,
	discountRate,
	discountType,
	navPath
}: StorePreviewCardProps) {
	const { price, discountedPercentage } = useProductPrice({
		basePrice,
		customPriceOverride,
		discountRate,
		discountType
	});

	return (
		<NavLink to={navPath || `Product/${id}`} className="no-decoration">
			<Container>
				{image && <ImagePanel src={image} />}
				<InfoSection>
					<Title>{name}</Title>
					<PriceRow>
						<DiscountedPrice>{formatCurrencyValueFromServer(price)}</DiscountedPrice>
						{discountedPercentage && (
							<>
								<OriginalPrice>{formatCurrencyValueFromServer(basePrice)}</OriginalPrice>
								<DiscountBadge>-{discountedPercentage}%</DiscountBadge>
							</>
						)}
					</PriceRow>
				</InfoSection>
			</Container>
		</NavLink>
	);
}

export default StorePreviewCard;
