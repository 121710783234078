import React from 'react';

import Banner from '../components/Banner';
import FeaturedDealsSection from '../components/FeaturedDealsSection';
import GenericHeroImage from '../components/GenericHeroImage';
import Hero from '../components/Hero';
import PartnerStoreSection from '../components/PartnerStoreSection';
import PartnerStrip from '../components/PartnerStrip';
import { RouteDefinitions } from '../routeDefinitions';

function Home() {
	return (
		<>
			<Hero />
			<GenericHeroImage
				imageSrc={'/images/backgrounds/wul_bam_size.jpg'}
				height={'large'}
				fullWidth={true}
				buttonLink={'https://teams.breakmark.com/store/bam2025'}
			/>
			<FeaturedDealsSection />
			<PartnerStoreSection />
		</>
	);
}

export default Home;
