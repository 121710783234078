import { useMutation } from '@apollo/client';
/* eslint-disable no-prototype-builtins */
import styled from '@emotion/styled';
import { Grid, IconButton, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { Trash } from 'react-feather';
import { NavLink, useLoaderData, useParams } from 'react-router-dom';

import Button from '../../components/Button';
import BodyWrapper from '../../core/components/BodyWrapper';
import { CreateCheckoutDocument, GetProductByIdQuery, GetStoreContentsQuery } from '../../generated/graphql';
import { useBreadcrumbs } from '../../providers/breadcrumbProvider';
import { Product, removeProductFromList } from '../../stores/cart/action';
import { useCartStore } from '../../stores/updatedCart/cartStore';
import CartCheckout, { CheckoutOptions } from './CartCheckout';
import TeamCartItemDetails from './TeamCartItemDetails';
import TeamCartItemHeader from './TeamCartItemHeader';

const ActionsWrapper = styled('div')`
	display: inline-flex;
	gap: 8px;
	width: 110px;
	margin-top: 12px;
`;

type PanelProps = {
	src: string;
};
const ProductImage = styled('div')(
	(props: PanelProps) => `
        background-color: var(--white);
        background-image: url("${props.src}");
        background-size: contain;
        aspect-ratio: 1;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 20px;
        min-height: 60px;
        border: 1px solid var(--separator);
    `
);

type Params = {
	storeId: string;
};

const TeamCartItemsWrapper = styled('div')`
	flex: 1;
	display: flex;
	flex-direction: column;
	border: 1px solid var(--separator);
	border-radius: 24px;
`;

const TeamCartLogo = styled('div')`
	display: inline-flex;
	width: 24px;
	height: 24px;
	background-color: var(--separator);
	border-radius: 50%;
	margin-right: 8px;
	border: 2px solid var(--success);
`;

const TeamCartName = styled('p')`
	font-weight: bold;
	display: flex;
	align-items: center;
	padding: 12px;
	border-bottom: 1px solid var(--separator);
`;

const CartItem = styled('div')`
	flex: 1;
	display: flex;
	padding: 20px;
	border-bottom: 1px solid var(--separator);

	&:last-of-type {
		border-bottom: none;
	}
`;

const formatProductForServer = (product: Product) => {
	const { options, ...rest } = product;

	const builtItem: any = {
		...rest
	};

	if (options.hasOwnProperty('custom_name')) {
		builtItem.custom_name = options.custom_name ? options.custom_name : ' ';
	}
	if (options.hasOwnProperty('custom_number')) {
		builtItem.custom_number = options.custom_number ? options.custom_number : ' ';
	}
	const { custom_name, custom_number, ...restOfOptions } = options;
	builtItem.options = Object.values(restOfOptions);
	return builtItem;
};

function TeamCart() {
	const data = useLoaderData() as GetStoreContentsQuery;
	const storeInfo = data.team_store[0];
	const storeData = useCartStore((state) => state.storeData);
	const removeProduct = useCartStore((state) => state.removeProduct);
	const { storeId } = useParams<Params>() as Params;
	const { enqueueSnackbar } = useSnackbar();
	const { addBreadcrumb, removeBreadcrumb } = useBreadcrumbs();
	const [createCheckout, { loading, data: checkoutData }] = useMutation(CreateCheckoutDocument);

	const cartItems = storeData[storeId] || [];

	useEffect(() => {
		const breadcrumb = { name: 'Checkout', path: `/TeamStore/${storeInfo.id}` };
		addBreadcrumb(breadcrumb);

		return () => {
			removeBreadcrumb(breadcrumb.path);
		};
	}, []);

	useEffect(() => {
		if (checkoutData?.createCheckout?.error) {
			enqueueSnackbar(checkoutData?.createCheckout?.error, {
				variant: 'error'
			});
		} else if (checkoutData?.createCheckout?.urlRedirect) {
			window.location.href = checkoutData.createCheckout.urlRedirect;
		}
	}, [checkoutData]);

	const handleRemoveItem = (index: number) => {
		removeProduct({ storeId, productIndex: index });
	};

	const cartTotal = useMemo(() => {
		if (!cartItems || cartItems.length === 0) {
			return 0;
		}

		return cartItems.reduce((prev, cur) => {
			const quantity = cur.type === 'product' ? cur.quantity : 1;
			return prev + (quantity ? quantity : 1) * (cur?.price || 0);
		}, 0);
	}, [cartItems]);

	const checkout = (checkoutOptions: CheckoutOptions) => {
		if (!cartItems) {
			return;
		}

		const variables = {
			products: JSON.stringify(
				cartItems.map((cartItem) => {
					if (cartItem.type === 'package') {
						const { price, image, id, label, type, order_for, ...rest } = cartItem;
						return {
							id,
							label,
							price,
							image,
							type,
							order_for,
							products: rest.products.map(formatProductForServer)
						};
					}
					return formatProductForServer(cartItem);
				})
			),
			name: checkoutOptions.name,
			email: checkoutOptions.email,
			checkoutType: checkoutOptions.paymentType,
			storeId: storeId,
			...(checkoutOptions.attributionSelected && { attributeTo: checkoutOptions.attributionSelected })
		};

		createCheckout({
			variables
		});
	};

	return (
		<BodyWrapper>
			<Grid container columnSpacing={2} rowSpacing={2}>
				<Grid item xs={12} md={8}>
					<TeamCartItemsWrapper>
						<TeamCartName>
							<TeamCartLogo></TeamCartLogo>
							<NavLink to={`/TeamStore/${storeInfo.id}`}>{storeInfo.name} </NavLink>
						</TeamCartName>
						{cartItems.map((item, index) => (
							<CartItem key={item.id}>
								<Grid container columnSpacing={2} rowSpacing={2}>
									<Grid item xs={3}>
										<ProductImage src={item.image} />
									</Grid>
									<Grid item xs={9}>
										<div>
											<TeamCartItemHeader orderItem={item} />
											<TeamCartItemDetails orderItem={item} />
										</div>
										<ActionsWrapper>
											<Button
												variant={'secondary'}
												padding="small"
												onClick={() => handleRemoveItem(index)}
												startIcon={<Trash />}
											>
												Remove
											</Button>
										</ActionsWrapper>
									</Grid>
								</Grid>
							</CartItem>
						))}
					</TeamCartItemsWrapper>
				</Grid>
				<CartCheckout
					itemCount={cartItems.length || 0}
					cartTotal={cartTotal}
					storeInfo={storeInfo}
					handleCheckout={checkout}
				/>
			</Grid>
		</BodyWrapper>
	);
}

export default TeamCart;
