import { useQuery } from '@apollo/client';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Drawer, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import SideNavFilter from '../../components/SideNavFilter';
import StorePreviewCard from '../../components/StorePreviewCard';
import BodyWrapper from '../../core/components/BodyWrapper';
import { GetPublicProductsForBreakmarkDocument } from '../../generated/graphql';
import { RouteDefinitions } from '../../routeDefinitions';
import { PreprocessPublicProduct } from '../ProductDetail/ParseServerProductUtilities';
import { TeamBundleApparelJson } from './teamApparelJson';

// Updated filter categories with readable labels
const filterCategories = [
	{
		name: 'Category',
		options: [
			{ label: 'Bundle', value: 'bundle' },
			{ label: 'Shirt', value: 'shirt' },
			{ label: 'Shorts', value: 'shorts' },
			{ label: 'Hat', value: 'hat' },
			{ label: 'Blanket', value: 'blanket' },
			{ label: 'Bandanas', value: 'banas' },
			{ label: 'Socks', value: 'socks' },
			{ label: 'Reversible', value: 'reversible' }
		]
	},
	{
		name: 'Type',
		options: [
			{ label: 'Sleeveless', value: 'sleeveless' },
			{ label: 'Long Sleeve', value: 'long sleeve' },
			{ label: 'Short Sleeve', value: 'short sleeve' },
			{ label: 'Tank', value: 'tank' },
			{ label: 'Polo', value: 'polo' },
			{ label: 'Hooded', value: 'hood' }
		]
	},
	{
		name: 'Size',
		options: [
			{ label: 'Youth', value: 'youth' },
			{ label: 'Women', value: 'women' },
			{ label: 'Men', value: 'men' }
		]
	},
	{
		name: 'Features',
		options: [
			{ label: 'Customizable', value: 'customizable' },
			{ label: 'Solid Color', value: 'solid' },
			{ label: 'Full Design', value: 'full' }
		]
	}
];

function TeamApparelStoreConnected() {
	const [filters, setFilters] = useState<Record<string, Set<string>>>({});
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const navigate = useNavigate();

	const { data, loading } = useQuery(GetPublicProductsForBreakmarkDocument);

	console.log(data);

	const location = useLocation();
	const { category, subcategory } = useParams<{ category?: string; subcategory?: string }>();
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const nextFilters: Record<string, Set<string>> = {};

		filterCategories.forEach((fc) => {
			const paramName = fc.name.toLowerCase();
			const paramValues = searchParams.getAll(paramName);
			if (paramValues.length > 0) {
				nextFilters[fc.name] = new Set(paramValues);
			}
		});

		if (category) {
			nextFilters.Category = new Set([category]);
		}
		if (subcategory) {
			nextFilters.Type = new Set([subcategory]);
		}

		setFilters(nextFilters);
	}, [location.search, category, subcategory]);

	const updateUrlParams = useCallback(
		(updatedFilters: Record<string, Set<string>>) => {
			const params = new URLSearchParams();
			Object.entries(updatedFilters).forEach(([cat, values]) => {
				values.forEach((v) => params.append(cat.toLowerCase(), v));
			});
			navigate({ pathname: location.pathname, search: params.toString() });
		},
		[navigate, location.pathname]
	);

	const handleFilterChange = useCallback(
		(categoryName: string, option: string, checked: boolean) => {
			setFilters((prev) => {
				const newFilters = { ...prev };
				if (!newFilters[categoryName]) {
					newFilters[categoryName] = new Set();
				}
				if (checked) {
					newFilters[categoryName].add(option);
				} else {
					newFilters[categoryName].delete(option);
				}
				updateUrlParams(newFilters);
				return newFilters;
			});
		},
		[updateUrlParams]
	);

	const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if (
			event.type === 'keydown' &&
			((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
		) {
			return;
		}
		setIsDrawerOpen(open);
	};

	const filteredProducts = useMemo(() => {
		return TeamBundleApparelJson.filter((product) => {
			return Object.entries(filters).every(([category, selectedOptions]) => {
				if (selectedOptions.size === 0) return true;
				return Array.from(selectedOptions).some((option) => product.tags.includes(option));
			});
		});
	}, [filters]);

	const memoizedProducts = useMemo(() => {
		return data?.product.map((product) => PreprocessPublicProduct(product)) || [];
	}, [data]);

	const filterComponent = (
		<>
			<Typography variant="h6" gutterBottom>
				Product Filters
			</Typography>
			<SideNavFilter categories={filterCategories} filters={filters} onFilterChange={handleFilterChange} />
		</>
	);

	return (
		<BodyWrapper>
			<Grid container columnSpacing={2} rowSpacing={2}>
				{isMobile && (
					<Grid item xs={12} sx={{ mb: 2 }}>
						<Button variant="outlined" startIcon={<FilterListIcon />} onClick={toggleDrawer(true)} fullWidth>
							Filters
						</Button>
						<Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
							<div style={{ padding: '16px', width: '250px' }}>{filterComponent}</div>
						</Drawer>
					</Grid>
				)}
				{!isMobile && (
					<Grid item xs={3}>
						{filterComponent}
					</Grid>
				)}
				<Grid item xs={12} sm={9}>
					<Grid container rowSpacing={2} columnSpacing={2}>
						{memoizedProducts.map((bundle) => (
							<Grid key={bundle.id} item xs={6} sm={4} md={3}>
								<StorePreviewCard
									key={bundle.id}
									id={bundle.id}
									image={bundle.displayPhoto}
									name={bundle.name}
									basePrice={bundle.basePrice}
									customPriceOverride={bundle.customPriceOverride}
									navPath={RouteDefinitions.TEAM_APPAREL_ITEM_V2(bundle.id)}
								/>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>
		</BodyWrapper>
	);
}

export default TeamApparelStoreConnected;
