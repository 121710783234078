import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import TeamSalesDialog, { TeamSalesFormData } from '../../components/Dialogs/TeamSalesDialog';
import ProductDetailGenericActions from '../../components/ProductDetailGenericActions';
import ProductSection from '../../components/ProductSection';
import TeamApparelFAQ from '../../components/TeamApparelFAQ';
import { GetPublicProductByIdForBreakmarkDocument } from '../../generated/graphql';
import { PreprocessPublicProduct } from '../ProductDetail/ParseServerProductUtilities';
import ProductDetail from '../ProductDetail/ProductDetail';

function TeamApparelItemConnected() {
	const { productId } = useParams();

	const { data, loading } = useQuery(GetPublicProductByIdForBreakmarkDocument, {
		variables: {
			productId: productId as string
		}
	});

	const [dialogOpen, setDialogOpen] = useState(false);

	const handleOpenDialog = () => setDialogOpen(true);
	const handleCloseDialog = () => setDialogOpen(false);

	const handleTeamSalesSubmit = (formData: TeamSalesFormData) => {
		handleCloseDialog();
	};

	const memoizedGenericAction = useMemo(() => {
		return <ProductDetailGenericActions buttonAction={handleOpenDialog} buttonLabel={'Start your Order!'} />;
	}, []);

	if (!data || !data?.product || !data?.product[0]) {
		return (
			<ProductSection>
				<div> No product found</div>
			</ProductSection>
		);
	}

	const product = PreprocessPublicProduct(data.product[0]);
	const customDetails = <TeamApparelFAQ />;

	return (
		<ProductSection>
			<Grid container rowSpacing={2} columnSpacing={3}>
				<ProductDetail
					product={product}
					disableQuantityField
					customActionContainer={memoizedGenericAction}
					customDetails={customDetails}
				/>
			</Grid>
			{dialogOpen && (
				<TeamSalesDialog
					open={dialogOpen}
					onClose={handleCloseDialog}
					onSubmit={handleTeamSalesSubmit}
					productId={productId}
				/>
			)}
		</ProductSection>
	);
}

export default TeamApparelItemConnected;
