import styled from '@emotion/styled';
import { Tab, Tabs } from '@mui/material';

export const BreadcrumbsWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	width: 95%;
	max-width: 1240px;
	margin: auto;
`;

export const Separator = styled('div')`
	width: 100%;
	border-bottom: 1px solid var(--separator);
	margin: 0 0 12px;
`;

export const ProductTabs = styled('section')`
	display: flex;
	flex-direction: column;
	width: 95%;
	max-width: 1240px;
	margin: auto;
`;

export const StyledTabs = styled(Tabs)`
	font-family: 'Satoshi-Variable', sans-serif;

	.MuiTabs-indicator {
		background-color: var(--black);
	}
`;

export const StyledTab = styled(Tab)`
	font-family: 'Satoshi-Variable', sans-serif;

	&.Mui-selected {
		color: var(--black);
		font-weight: 600;
	}
`;

interface ProductImageProps {
	src: string;
	onClick?: () => void;
	selected?: boolean;
}

export const ProductImage = styled.div<ProductImageProps>`
	background-color: var(--white);
	background-image: url(${(props) => props.src});
	background-size: contain;
	aspect-ratio: 1;
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 20px;
	border: 1px solid var(--separator);
	border-color: ${(props) => (props.selected ? 'var(--gradient-start)' : 'var(--separator)')};
	box-sizing: border-box;
	min-height: 60px;
	cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
	transition: border-color 0.3s ease;

	&:hover {
		border-color: var(--primary-hover);
	}
`;

export const ImageSelector = styled('div')({
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	gap: '0.5rem',
	justifyContent: 'center',
	'& img': {
		width: '60px',
		height: '60px',
		objectFit: 'cover'
	}
});

export const OptionsBlock = styled('div')`
	display: flex;
	gap: 12px;
	padding: 12px 0 16px;
	flex-wrap: wrap;
`;
