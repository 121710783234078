import styled from '@emotion/styled';
import React, { ReactElement } from 'react';

import { Package, Product } from '../../stores/cart/action';

interface Props {
	detailClassName?: string;
	orderItem: Package | Product;
}

interface LineItemProps {
	label: string;
	value: string;
	withLabel?: boolean;
}

const LineItem = (props: LineItemProps) => (
	<p className={'no-margin'}>
		{props.label}: <strong>{props.value}</strong>
	</p>
);

const TeamCartItemDetails = (props: Props): ReactElement | null => {
	const { orderItem } = props;

	if (!orderItem.type) {
		return null;
	}
	if (orderItem.type === 'package') {
		const optDesc = orderItem.products.map((product, i) => {
			const { custom_name, custom_number, quantity, ...rest } = product.options;
			const desc = Object.values(rest).map((item) => (
				<LineItem value={item.value ? item.value : ' '} label={item.label} key={item.availableOptionId} />
			));

			if (custom_name !== undefined) {
				desc.push(
					<LineItem
						withLabel
						value={custom_name ? `${custom_name}` : ' '}
						label="Custom Name"
						key={`custom-name-${i}`}
					/>
				);
			}
			if (custom_number !== undefined) {
				desc.push(
					<LineItem
						withLabel
						value={custom_number ? `${custom_number}` : ' '}
						label="Custom Number"
						key={`custom-number-${i}`}
					/>
				);
			}

			return (
				<StyledPackageProduct key={`pack_${product.id}`}>
					<StyledLabel>
						<strong>{product.label}</strong>
					</StyledLabel>
					<div className={props.detailClassName}>{desc.filter((a) => a)}</div>
				</StyledPackageProduct>
			);
		});
		return <>{optDesc.filter((a) => a)}</>;
	} else if (orderItem.type === 'product') {
		const desc = Object.values(orderItem.options).map((item) => (
			<LineItem value={item.value ? item.value : ' '} label={item.label} key={item.availableOptionId} />
		));

		if (orderItem.custom_name !== undefined) {
			desc.push(
				<LineItem
					withLabel
					value={orderItem.custom_name ? `${orderItem.custom_name}` : ' '}
					label="Custom Name"
					key={`custom-name-${orderItem.id}`}
				/>
			);
		}
		if (orderItem.custom_number !== undefined) {
			desc.push(
				<LineItem
					withLabel
					value={orderItem.custom_number ? `${orderItem.custom_number}` : ' '}
					label="Custom Number"
					key={`custom-number-${orderItem.id}`}
				/>
			);
		}

		return <div className={props.detailClassName}>{desc.filter((a) => a)}</div>;
	}

	return null;
};

const StyledLabel = styled.div`
	border-bottom: 1px solid var(--separator);
	margin-bottom: 10px;
`;

const StyledPackageProduct = styled.div`
	padding: 10px 0;
`;

export default TeamCartItemDetails;
