import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { Clear, PlaylistRemove } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounceCallback } from 'usehooks-ts';

import Button from '../../components/Button';
import { formatCurrencyValueFromServer } from '../../core/utilities/currency';
import { CreateDemoStoreDocument } from '../../generated/graphql';
import useBundleStorage from '../../hooks/useBundleStorage';

const BundleStoreViewer = () => {
	const navigate = useNavigate();
	const { bundle, removeBundleProduct, resetBundleStorage, updateBundleName } = useBundleStorage();
	const [name, setName] = useState(bundle.name);
	const [createDemoStore] = useMutation(CreateDemoStoreDocument);

	const debounced = useDebounceCallback(setName, 1500);

	useEffect(() => {
		if (name !== bundle.name) {
			updateBundleName(name);
		}
	}, [name, bundle.name, updateBundleName]);

	const CartWrapper = styled('div')`
		background-color: var(--grey);
		padding: 6px;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		gap: 6px;
	`;

	const FlexBox = styled('div')`
		display: flex;
	`;

	const BundleName = styled('input')`
		flex: 1;
		border-radius: 6px;
		padding: 2px 6px;
		font-size: 16px;
		font-weight: bold;
		border: none;
		background-color: #fafafa;
	`;

	const BundleRow = styled('div')`
		display: flex;
		align-items: center;
	`;

	const BundleSeparator = styled('div')`
		border-bottom: 1px solid var(--typography-muted);
		margin: 2px -4px;
	`;

	const BundleRowName = styled('p')`
		flex: 1;
		margin: 0;
		font-weight: 500;
	`;

	const BundleRowPrice = styled('p')`
		margin: 0;
	`;

	const BundleIconButton = styled(IconButton)`
		padding: 4px;
		margin-left: 6px;
		margin-right: -2px;
	`;

	const BundleTotal = styled('p')`
		flex: 1;
		margin: 0 0 10px 10px;
		font-weight: 900;
	`;
	const BundleTotalPrice = styled('p')`
		font-weight: 900;
		font-size: 18px;
		margin: 0 15px 10px 0;
	`;

	const DiscountBadge = styled('div')`
		background: var(--discount-bg);
		color: var(--typography-discount);
		padding: 4px;
		border-radius: 10px;
		height: 24px;

		small {
			font-size: 12px;
		}

		margin-right: 20px;
	`;

	const isValid = () => {
		return bundle.name.length > 0 && bundle.products.length > 0;
	};

	const submitBundle = async () => {
		if (isValid()) {
			try {
				const results = await createDemoStore({
					variables: {
						storeLabel: bundle.name,
						bundleIds: [bundle.products.map((p) => p.id)]
					}
				});
				navigate('/');
			} catch (error) {
				console.error(error);
			}
		}
	};

	if (bundle) {
		return (
			<CartWrapper>
				<FlexBox>
					<BundleName
						type={'text'}
						placeholder={'Bundle Name'}
						defaultValue={name}
						onChange={(e) => {
							debounced(e.target.value);
						}}
					/>
					<Tooltip title={'Start My Bundle Over'}>
						<BundleIconButton onClick={() => resetBundleStorage()}>
							<PlaylistRemove />
						</BundleIconButton>
					</Tooltip>
				</FlexBox>
				<BundleSeparator />
				{bundle.products.map((p, index) => (
					<>
						<BundleRow key={index}>
							<BundleRowName>{p.label}</BundleRowName>
							<BundleRowPrice>{formatCurrencyValueFromServer(p.base_price || 0)}</BundleRowPrice>
							<BundleIconButton onClick={() => removeBundleProduct(index)}>
								<Clear fontSize="small" />
							</BundleIconButton>
						</BundleRow>
						<BundleSeparator />
					</>
				))}
				<FlexBox>
					<BundleTotal>Total</BundleTotal>
					<DiscountBadge>
						-<small>20%</small>
					</DiscountBadge>
					<BundleTotalPrice>{formatCurrencyValueFromServer(bundle.total || 0)}</BundleTotalPrice>
				</FlexBox>
				<Button variant={'gray'} padding={'small'} fullWidth={true} onClick={submitBundle}>
					Submit Bundle Request
				</Button>
			</CartWrapper>
		);
	} else {
		return null;
	}
};

export default BundleStoreViewer;
