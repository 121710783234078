import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import React, { memo, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { GetPackageProductsByIdDocument, StorePackageFragment } from '../generated/graphql';
import StorePreviewCard from './StorePreviewCard';

export interface Product {
	id: string;
	image?: string;
	name: string;
	stars?: number;
	basePrice: number;
	customPriceOverride?: number;
	discountRate?: number | undefined;
	discountType?: 'dollar' | 'percent' | undefined;
}

interface Props {
	pkg: StorePackageFragment;
	discountRate?: number | undefined;
	discountType?: 'dollar' | 'percent' | undefined;
}

const BundlePreviewContainer = styled('div')`
	display: flex;
	flex-direction: column;
	gap: 8px;
	cursor: pointer;

	@media only screen and (max-width: 900px) {
	}
`;

type PanelProps = {
	src: string;
};

const ProductImage = styled('div')<PanelProps>`
	background-color: var(--white);
	background-image: url('${(props) => props.src}');
	background-size: contain;
	aspect-ratio: 1;
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 20px;
	border: 1px solid var(--separator);
`;

const ProductName = styled('h6')`
	margin-left: 8px;
	margin-top: 8px;
	margin-bottom: 0;
`;

const BundlePreview = memo(({ pkg }: Props) => {
	const { data } = useQuery(GetPackageProductsByIdDocument, {
		variables: {
			_in: pkg.product_ids
		}
	});

	const basePrice = useMemo(() => {
		if (data) {
			return data.store_product.reduce((current, nextVal) => {
				return current + (nextVal.product.base_price ? nextVal.product.base_price : 0);
			}, 0);
		}
		return 0;
	}, [data]);

	return (
		<StorePreviewCard
			id={pkg.id}
			navPath={`bundle/${pkg.id}`}
			image={pkg.photo_url || undefined}
			name={pkg.label || 'Bundle'}
			basePrice={basePrice}
			customPriceOverride={pkg.custom_price}
		/>
	);
});

export default BundlePreview;
